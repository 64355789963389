export interface SynthCommand {
    name: string;
    modulation: number;
}

export const BYZNTINE_NOTES = {
    LOW_DI: 'LOW_DI',
    LOW_KE: 'LOW_KE',
    LOW_ZO: 'LOW_ZO',
    LOW_NI: 'LOW_NI',
    LOW_PA: 'LOW_PA',
    VU: 'VU',
    GA: 'GA',
    DI: 'DI',
    KE: 'KE',
    ZO: 'ZO',
    NI: 'NI',
    PA: 'PA',
} as const;

export type ByzantineNote = typeof BYZNTINE_NOTES[keyof typeof BYZNTINE_NOTES];

export const SCALES = {
    DIATONIC: 'DIATONIC',
    ENHARMONIC: 'ENHARMONIC',
    HARD_CHROMATIC: 'HARD_CHROMATIC',
    SOFT_CHROMATIC: 'SOFT_CHROMATIC',
    GRAVE_DIATONIC: 'GRAVE_DIATONIC',
} as const;

export type Scale = typeof SCALES[keyof typeof SCALES];

export const KEY_MODES = {
    SUSTAIN: 'sustain',
    TOUCH: 'touch',
} as const;

export type KeyMode = typeof KEY_MODES[keyof typeof KEY_MODES];
