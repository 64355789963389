import React from 'react';
import ClassNames from 'classnames';
import * as Tone from 'tone';

import './IsonKey.css';
import { ByzantineNote } from '../../types';

interface IsonKeyProps {
    label: string;
    value: ByzantineNote;
    active: boolean;
    color: string;
    onTouchEnd: (keyValue: ByzantineNote) => void;
    onTouchStart: (keyValue: ByzantineNote) => void;
    disabled?: boolean;
}

const IsonKey: React.FC<IsonKeyProps> = ({
    label,
    value,
    active,
    color,
    onTouchEnd,
    onTouchStart,
    disabled = false,
}): React.ReactElement => {
    const touchStartHandler = async (): Promise<void> => {
        if (disabled) {
            return;
        }
        await Tone.start();
        onTouchStart(value);
    };

    const touchEndHandler = async (): Promise<void> => {
        if (disabled) {
            return;
        }
        onTouchEnd(value);
    };

    const isonKeyClasses = ClassNames('ison-key', {
        active,
        disabled,
    });

    return (
        <div
            className={isonKeyClasses}
            onTouchStart={touchStartHandler}
            onTouchEnd={touchEndHandler}
            onTouchCancel={touchEndHandler}
            style={{ backgroundColor: color }}
        >
            <div className="ison-key-label">{label}</div>
        </div>
    );
};

export default IsonKey;
