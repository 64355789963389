import { ByzantineNote, Scale, SynthCommand } from '../types';
import scaleMap from '../resources/scales.json';
import { WESTERN_NOTES } from '../constants';

const byzantineToSynthCommand = (
    base: number,
    scale: Scale,
    byzantineNote: ByzantineNote
): SynthCommand => {
    let byzantineNoteValue = base;

    for (let [key, value] of Object.entries(scaleMap[scale])) {
        byzantineNoteValue += value;
        if (key === byzantineNote) {
            break;
        }
    }

    const closestWesternValue = WESTERN_NOTES.reduce(
        (max, note) =>
            note.baseCents <= byzantineNoteValue && note.baseCents > max
                ? note.baseCents
                : max,
        0
    );

    return {
        name: WESTERN_NOTES.filter(
            (note) => note.baseCents === closestWesternValue
        )[0].name,
        modulation: byzantineNoteValue - closestWesternValue,
    };
};

export default byzantineToSynthCommand;
