import { BYZNTINE_NOTES } from './types';

export const CENT_SEMITONE_RATO = 1 / 6;

export const ISON_KEY_VALUES = [
    {
        label: 'δι',
        value: BYZNTINE_NOTES.LOW_DI,
        color: '#1a1334',
    },
    {
        label: 'κε',
        value: BYZNTINE_NOTES.LOW_KE,
        color: '#26294a',
    },
    {
        label: 'ζω',
        value: BYZNTINE_NOTES.LOW_ZO,
        color: '#017351',
    },
    {
        label: 'νι',
        value: BYZNTINE_NOTES.LOW_NI,
        color: '#03c383',
    },
    {
        label: 'πα',
        value: BYZNTINE_NOTES.LOW_PA,
        color: '#aad962',
    },
    {
        label: 'Βου',
        value: BYZNTINE_NOTES.VU,
        color: '#fbbf45',
    },
    {
        label: 'Γα',
        value: BYZNTINE_NOTES.GA,
        color: '#ef6a32',
    },
    {
        label: 'Δι',
        value: BYZNTINE_NOTES.DI,
        color: '#ed0345',
    },
    {
        label: 'Κε',
        value: BYZNTINE_NOTES.KE,
        color: '#a12a5e',
    },
    {
        label: 'Ζο',
        value: BYZNTINE_NOTES.ZO,
        color: '#710162',
    },
    {
        label: 'Νι',
        value: BYZNTINE_NOTES.NI,
        color: '#022c7d',
    },
    {
        label: 'Πα',
        value: BYZNTINE_NOTES.PA,
        color: '#01545a',
    },
];

export const WESTERN_NOTES = [
    {
        name: 'G2',
        value: 43,
        baseCents: 0,
    },
    {
        name: 'A2',
        value: 45,
        baseCents: 12,
    },
    {
        name: 'B2',
        value: 47,
        baseCents: 24,
    },
    {
        name: 'C3',
        value: 48,
        baseCents: 30,
    },
    {
        name: 'D3',
        value: 50,
        baseCents: 42,
    },
    {
        name: 'E3',
        value: 52,
        baseCents: 54,
    },
    {
        name: 'F3',
        value: 53,
        baseCents: 60,
    },
    {
        name: 'G3',
        value: 55,
        baseCents: 72,
    },
    {
        name: 'A3',
        value: 57,
        baseCents: 84,
    },
    {
        name: 'B3',
        value: 59,
        baseCents: 96,
    },
    {
        name: 'C4',
        value: 60,
        baseCents: 102,
    },
];

export const MAX_BASE_COMMAS = 36;
export const MIN_BASE_COMMAS = -36;
export const COMMA_INTERVAL = 2;

export const YFESIS_VALUE = -4;
