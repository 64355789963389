import React, { useState, useContext } from 'react';

import { IsonKey } from '../IsonKey';
import { OptionsContext } from '../../context/optionsContext';

import { ISON_KEY_VALUES } from '../../constants';
import { ByzantineNote } from '../../types';
import scaleMap from '../../resources/scales.json';

import './IsokratisView.css';
import { BottomBar } from '../BottomBar';
import { useIsokratisSynth } from '../../hooks/useIsokratisSynth';

const IsokratisView: React.FC = (): React.ReactElement => {
    const [activeKey, setActiveKey] = useState<ByzantineNote>();
    const { selectedScale, isNoteSustained } = useContext(OptionsContext);

    useIsokratisSynth(activeKey);

    const isonKeyTouchStartHandler = (keyValue: ByzantineNote): void => {
        if (activeKey === keyValue) {
            setActiveKey(undefined);
            return;
        }

        setActiveKey(keyValue);
    };

    const isonKeyTouchEndHandler = (keyValue: ByzantineNote): void => {
        if (!isNoteSustained && keyValue === activeKey) {
            setActiveKey(undefined);
        }
    };

    return (
        <div className="isokratis-view">
            <div className="ison-key-grid">
                {ISON_KEY_VALUES.map(
                    ({ label, value, color }): React.ReactElement => (
                        <IsonKey
                            key={value}
                            label={label}
                            value={value}
                            color={color}
                            onTouchStart={isonKeyTouchStartHandler}
                            onTouchEnd={isonKeyTouchEndHandler}
                            active={value === activeKey}
                            disabled={
                                !Object.keys(scaleMap[selectedScale]).includes(
                                    value
                                )
                            }
                        />
                    )
                )}
            </div>
            <BottomBar />
        </div>
    );
};

export default IsokratisView;
