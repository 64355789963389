import React, { useState } from 'react';
import { SCALES, Scale } from '../types';

import { OptionsContext } from './optionsContext';

interface OptionsContextProviderProps {
    children: React.ReactNode;
}

const OptionsContextProvider: React.FC = ({
    children,
}): React.ReactElement<OptionsContextProviderProps> => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isNoteSustained, setIsNoteSustained] = useState<boolean>(false);
    const [isYfesisSustained, setIsYfesisSustained] = useState<boolean>(false);
    const [baseCommas, setBaseCommas] = useState<number>(0);
    const [selectedScale, setSelectedScale] = useState<Scale>(SCALES.DIATONIC);
    const [isYfesis, setIsYfesis] = useState<boolean>(false);

    return (
        <OptionsContext.Provider
            value={{
                isMenuOpen,
                setIsMenuOpen,
                selectedScale,
                setSelectedScale,
                isNoteSustained,
                setIsNoteSustained,
                baseCommas,
                setBaseCommas,
                isYfesis,
                setIsYfesis,
                isYfesisSustained,
                setIsYfesisSustained,
            }}
        >
            {children}
        </OptionsContext.Provider>
    );
};

export default OptionsContextProvider;
