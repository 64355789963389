import React, { useContext } from 'react';

import {
    COMMA_INTERVAL,
    MAX_BASE_COMMAS,
    MIN_BASE_COMMAS,
    YFESIS_VALUE,
} from '../../constants';
import { OptionsContext } from '../../context/optionsContext';

import './BaseInput.css';

interface BaseInputProps {
    value: number;
    onChange: (value: number) => void;
}

const BaseInput: React.FC<BaseInputProps> = ({
    value,
    onChange,
}): React.ReactElement => {
    const { isYfesis } = useContext(OptionsContext);

    const plusClickHandler = (): void => {
        if (value + COMMA_INTERVAL <= MAX_BASE_COMMAS) {
            onChange(value + COMMA_INTERVAL);
        }
    };

    const minusClickHandler = (): void => {
        if (value - COMMA_INTERVAL >= MIN_BASE_COMMAS) {
            onChange(value - COMMA_INTERVAL);
        }
    };

    return (
        <div className="base-input">
            <div
                className="base-input-button"
                onClick={(): void => minusClickHandler()}
            >
                <div className="base-input-minus" />
            </div>
            <div className="base-input-value">
                {value + (isYfesis ? YFESIS_VALUE : 0)}
            </div>
            <div
                className="base-input-button"
                onClick={(): void => plusClickHandler()}
            >
                <div className="base-input-plus" />
            </div>
        </div>
    );
};

export default BaseInput;
