import React from 'react';
import ClassNames from 'classnames';

import './MenuEntry.css';

interface MenuEntryProps {
    name: string;
    selected: boolean;
    onClick: () => void;
}

const MenuEntry: React.FC<MenuEntryProps> = ({
    name,
    selected,
    onClick,
}): React.ReactElement => {
    const menuEntryClasses = ClassNames('menu-entry', {
        selected,
    });

    return (
        <div className={menuEntryClasses} onClick={onClick}>
            {name}
        </div>
    );
};

export default MenuEntry;
