import React from 'react';
import Div100vh from 'react-div-100vh';
import useWindowOrientation from 'use-window-orientation';

import OptionsContextProvider from '../../context/OptionsContextProvider';
import { IsokratisView } from '../IsokratisView';
import { AppMenu } from '../AppMenu';
import { TopBar } from '../TopBar';

import './App.css';

const App: React.FC = (): React.ReactElement => {
    const { portrait, landscape } = useWindowOrientation();

    return (
        <>
            {portrait && (
                <Div100vh className="app">
                    <OptionsContextProvider>
                        <TopBar />
                        <IsokratisView />
                        <AppMenu />
                    </OptionsContextProvider>
                </Div100vh>
            )}
            {landscape && <div>Please rotate your phone.</div>}
        </>
    );
};

export default App;
