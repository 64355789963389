import React, { useContext } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import ClassNames from 'classnames';

import { OptionsContext } from '../../context/optionsContext';

import { ReactComponent as YfesisIcon } from '../../resources/yfesis.svg';

import './TopBar.css';

const TopBar: React.FC = (): React.ReactElement => {
    const {
        isMenuOpen,
        setIsMenuOpen,
        isYfesis,
        setIsYfesis,
        isYfesisSustained,
    } = useContext(OptionsContext);

    const handleMenuClicked = (): void => {
        setIsMenuOpen(!isMenuOpen);
    };

    const yfesisUpHandler = (): void => {
        if (isYfesisSustained) {
            setIsYfesis(!isYfesis);
            return;
        }
        setIsYfesis(false);
    };

    const yfesisDownHandler = (): void => {
        if (isYfesisSustained) {
            return;
        }
        setIsYfesis(true);
    };

    const yfesisButtonClasses = ClassNames('top-bar-yfesis-button', {
        active: isYfesis,
    });

    const yfesisIconClasses = ClassNames('top-bar-yfesis', {
        active: isYfesis,
    });

    return (
        <div className="top-bar">
            <HamburgerMenu
                isOpen={isMenuOpen}
                menuClicked={handleMenuClicked}
                color="white"
                width={25}
                height={20}
            />
            <div className="top-bar-title">isokratis.io</div>
            <div
                className={yfesisButtonClasses}
                onTouchStart={yfesisDownHandler}
                onTouchEnd={yfesisUpHandler}
            >
                <YfesisIcon className={yfesisIconClasses} />
            </div>
        </div>
    );
};

export default TopBar;
