import React, { useContext } from 'react';

import './BottomBar.css';
import { OptionsContext } from '../../context/optionsContext';
import { BaseInput } from '../BaseInput';

const BottomBar: React.FC = (): React.ReactElement => {
    const { baseCommas, setBaseCommas } = useContext(OptionsContext);

    return (
        <div className="bottom-bar">
            <BaseInput value={baseCommas} onChange={setBaseCommas} />
        </div>
    );
};

export default BottomBar;
