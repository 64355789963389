import React from 'react';
import ClassNames from 'classnames';

import './MenuToggle.css';

interface MenuToggleProps {
    name: string;
    active: boolean;
    onClick: () => void;
}

const MenuToggle: React.FC<MenuToggleProps> = ({
    name,
    active,
    onClick,
}): React.ReactElement => {
    const MenuToggleSwitchClasses = ClassNames('menu-toggle-switch', {
        active,
    });

    return (
        <div className="menu-toggle" onClick={onClick}>
            <div className="menu-toggle-title">{name}</div>
            <div className={MenuToggleSwitchClasses} />
        </div>
    );
};

export default MenuToggle;
