import { useCallback, useContext, useEffect, useRef } from 'react';
import * as Tone from 'tone';
import { YFESIS_VALUE, CENT_SEMITONE_RATO, WESTERN_NOTES } from '../constants';
import { ByzantineNote, KEY_MODES } from '../types';
import byzantineToSynthCommand from '../util/byzantineToSynthCommand';
import { OptionsContext } from '../context/optionsContext';
import synthParams from '../resources/synthParams.json';

export const useIsokratisSynth = (
    activeKey: ByzantineNote | undefined
): void => {
    const synth = useRef<Tone.Synth | null>(null);
    const pitchShift = useRef<Tone.PitchShift | null>(null);
    const sampler = useRef<Tone.Sampler | null>(null);

    const { selectedScale, baseCommas, isYfesis, isNoteSustained } =
        useContext(OptionsContext);

    const setupSynth = useCallback(async (): Promise<void> => {
        pitchShift.current = new Tone.PitchShift().toDestination();
        sampler.current = new Tone.Sampler(
            WESTERN_NOTES.reduce(
                (acc, { name, value }) => ({
                    ...acc,
                    [value]: `sounds/${name}.mp3`,
                }),
                {}
            ),
            () => sampler.current?.triggerAttack('D3')
        ); //.connect(pitchShift.current);
        synth.current = new Tone.Synth(
            synthParams[isNoteSustained ? KEY_MODES.SUSTAIN : KEY_MODES.TOUCH]
        ).connect(pitchShift.current);
    }, [isNoteSustained]);

    useEffect((): void => {
        if (!pitchShift.current) {
            setupSynth();
            return;
        }

        if (activeKey) {
            synth.current?.triggerRelease(Tone.context.currentTime);

            const synthCommand = byzantineToSynthCommand(
                baseCommas + (isYfesis ? YFESIS_VALUE : 0),
                selectedScale,
                activeKey as ByzantineNote
            );

            pitchShift.current.pitch =
                synthCommand.modulation * CENT_SEMITONE_RATO;

            synth.current?.triggerAttack(
                synthCommand.name,
                Tone.context.currentTime
            );
        } else {
            synth.current?.triggerRelease(Tone.context.currentTime);
        }
    }, [activeKey, selectedScale, baseCommas, isYfesis, setupSynth]);

    useEffect(() => {
        if (synth.current) {
            synth.current.set(
                synthParams[
                    isNoteSustained ? KEY_MODES.SUSTAIN : KEY_MODES.TOUCH
                ]
            );
        }
    }, [isNoteSustained]);
};
