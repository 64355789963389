import React, { ReactNode } from 'react';

import './Submenu.css';

interface SubmenuProps {
    children: ReactNode[] | ReactNode;
    title: string;
}

const Submenu: React.FC<SubmenuProps> = ({
    children,
    title,
}): React.ReactElement => {
    return (
        <>
            <div className="submenu-title">{title}</div>
            <div className="submenu">{children}</div>
        </>
    );
};

export default Submenu;
