import React, { useContext } from 'react';
import { OptionsContext } from '../../context/optionsContext';
import ClassNames from 'classnames';

import { Scale, SCALES } from '../../types';
import localization from '../../resources/localization.json';

import { Submenu } from '../Submenu';
import { MenuEntry } from '../MenuEntry';
import { MenuToggle } from '../MenuToggle';

import './AppMenu.css';

const AppMenu: React.FC = (): React.ReactElement => {
    const {
        setIsMenuOpen,
        isMenuOpen,
        selectedScale,
        setSelectedScale,
        isNoteSustained,
        setIsNoteSustained,
        isYfesisSustained,
        setIsYfesisSustained,
    } = useContext(OptionsContext);

    const menuClasses = ClassNames('menu', {
        visible: isMenuOpen,
    });

    const menuOverlayClasses = ClassNames('menu-overlay', {
        visible: isMenuOpen,
    });

    const scaleSelectHandler = (scale: Scale): void => {
        setSelectedScale(scale);
        setIsMenuOpen(false);
    };

    const toggleNoteSustainHandler = (): void => {
        setIsNoteSustained(!isNoteSustained);
    };

    const toggleYfesisSustainHandler = (): void => {
        setIsYfesisSustained(!isYfesisSustained);
    };

    return (
        <>
            <div
                className={menuOverlayClasses}
                onClick={(): void => setIsMenuOpen(false)}
            />
            <div className={menuClasses}>
                <Submenu title="Settings">
                    <MenuToggle
                        name="Sustain note"
                        active={isNoteSustained}
                        onClick={toggleNoteSustainHandler}
                    />
                    <MenuToggle
                        name="Sustain yfesis"
                        active={isYfesisSustained}
                        onClick={toggleYfesisSustainHandler}
                    />
                </Submenu>
                <Submenu title="Scale">
                    {Object.values(SCALES).map((scale) => (
                        <MenuEntry
                            key={scale}
                            name={localization['en']['scales'][scale]}
                            onClick={(): void => scaleSelectHandler(scale)}
                            selected={selectedScale === scale}
                        />
                    ))}
                </Submenu>
            </div>
        </>
    );
};

export default AppMenu;
