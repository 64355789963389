import React from 'react';
import { Scale } from '../types';

interface OptionsContextType {
    isMenuOpen: boolean;
    setIsMenuOpen: (isOpen: boolean) => void;
    selectedScale: Scale;
    setSelectedScale: (scale: Scale) => void;
    isNoteSustained: boolean;
    setIsNoteSustained: (isSustained: boolean) => void;
    isYfesisSustained: boolean;
    setIsYfesisSustained: (isYfesisSustained: boolean) => void;
    baseCommas: number;
    setBaseCommas: (commas: number) => void;
    isYfesis: boolean;
    setIsYfesis: (isYfesis: boolean) => void;
}

const OptionsContext = React.createContext<OptionsContextType>(undefined!);

export { OptionsContext };
